import '../style/main.scss';

$('.dropdown-menu__item , .dropdown-menu__sub-item').on('click', function () {
    $(this).parent('.dropdown-menu').toggleClass('dropdown-menu_open')
})

$('.submenu-action').on('click', function () {
    $(this).siblings('.mobile-menu__sub').slideToggle(300)
    $(this).toggleClass('active')
})
$('.nav__item a ').on('click', function (e) {
    var href = $(this).attr('href')
    if (href[0] === '#') {
        e.preventDefault()
        scrollTo(href)
        $('body').removeClass('mobile-menu-open')
    }

})

function scrollTo(el) {
    $([document.documentElement, document.body]).animate({scrollTop: $(el).offset().top}, 1500)
}

function sendCallBack(name, phone) {
    $.ajax({
        type: "POST",
        url: '/static/backend/mailController.php',
        data: {
            name: name,
            phone: phone
        },
        success: function () {


        }
    });
}

$('.callback').on('click', function (e) {
    e.preventDefault()
    const name = $(this).parents('.form').find('input[name="name"]').val()
    const phone = $(this).parents('.form').find('input[name="phone"]').val()
    if (!name) {
        $('input[name="name"]').parents('.form__item').addClass('error-input')

    }
    if (!phone) {
        $('input[name="phone"]').parents('.form__item').addClass('error-input')

    }
    if (!phone || !name) {
        return false
    }

    sendCallBack(name, phone)

});
$('input[name="name"]').on('focus', function () {
    $(this).parents('.form__item').removeClass('error-input')
})
$('input[name="phone"]').on('focus', function () {
    $(this).parents('.form__item').removeClass('error-input')
})


$('body').on('click', function (e) {

    const div = $("#mobile-menu-button");
    const div2 = $(".mobile-menu");
    const div3 = $(".mobile-filter");
    const div4 = $(".header-top-right__search-block");
    const div5 = $(".search");
    const div6 = $(".catalog__filter");

    if (!div.is(e.target)
        && div.has(e.target).length === 0
        && !div2.is(e.target)
        && div2.has(e.target).length === 0
        && !div3.is(e.target)
        && div3.has(e.target).length === 0
        && !div4.is(e.target)
        && div4.has(e.target).length === 0
        && !div5.is(e.target)
        && div5.has(e.target).length === 0
        && !div6.is(e.target)
        && div6.has(e.target).length === 0)
    {
        $('body').removeClass('mobile-menu-open')
        $('body').removeClass('mobile-menu-open, mobile-filter-open, search-open')
    }
});

$('body').on('keyup', function (e) {
    if (e.keyCode === 27) {
        $('body').removeClass('mobile-menu-open')
    }
});
$('.catalog__filter-cross').on('click', function () {
    $('body').removeClass('mobile-filter-open')
});



//Слайдер на главной странице
const swiper = new Swiper('.swiper', {
    pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
    },
});

// Анимация плавной прокрутки
$(function(){
    $('a[href^="#"]').on('click', function(event) {
        // отменяем стандартное действие
        event.preventDefault();
        var sc = $(this).attr("href"),
            dn = $(sc).offset().top;
        /*
        * sc - в переменную заносим информацию о том, к какому блоку надо перейти
        * dn - определяем положение блока на странице
        */
        $('html, body').animate({scrollTop: dn}, 1000);

    });
});


$('.catalog__main-filter-item').on('click', function () {

    $('.catalog__main-filter-item').removeClass('active')
    $(this).addClass('active')
})

// листание форм


$('#btnup1').on('click', function () {
    $('#form1').addClass('hiden')
    $('#form2').removeClass('hiden')
})
$('#btnup2').on('click', function () {
    $('#form2').addClass('hiden')
    $('#form3').removeClass('hiden')
})
$('#btndown1').on('click', function () {
    $('#form2').addClass('hiden')
    $('#form1').removeClass('hiden')
})
$('#btndown2').on('click', function () {
    $('#form3').addClass('hiden')
    $('#form2').removeClass('hiden')
})

$('.mobile-filter').on('click', function () {


    $('body').addClass('mobile-filter-open')
});

if(location.href.indexOf('localhost') !== -1) {
    var swiper2 = new Swiper(" .product__main-slider", {
        loop: true,
        spaceBetween: 10,
        slidesPerView: 1,
        freeMode: true,
        watchSlidesProgress: true,


    });
}

if(location.href.indexOf('localhost') !== -1) {
var swiper3 = new Swiper(".product__two-slider", {
    loop: true,
    spaceBetween: 10,
    slidesPerView: 5,
    navigation: {
        nextEl: ".swiper-next",
        prevEl: ".swiper-prev",
    },
    thumbs: {
        swiper: swiper2,
    },
    breakpoints: {

        320: {
            slidesPerView: 4,
        },
        1400: {
            slidesPerView: 5,
        },
        thumbs: {
            swiper: swiper2,
        },
    },
});
}

if(location.href.indexOf('localhost') !== -1) {
    var swiper4 = new Swiper(" .additionally__slider", {
        loop: true,
        spaceBetween: 10,
        slidesPerView: 3,
        freeMode: true,
        watchSlidesProgress: true,
        navigation: {
            nextEl: ".swiper-next",
            prevEl: ".swiper-prev",
        },

        breakpoints: {
            320: {
                slidesPerView: 0.7,
            },
            600: {
                slidesPerView: 1,
            },
            768: {
                slidesPerView: 2,
            },
            1400: {
                slidesPerView: 3.5,
            },

        },
    });
}

$('.product__specifications-color').on('click', function () {
    $('.product__specifications-color').removeClass('active')
    $(this).toggleClass('active')
});
$('.characteristic__title').on('click', function (){
    const index = $(this).index()
    $('.characteristic__title').removeClass('active')
    $(this).addClass('active')
    $('.characteristic__bottom').hide().eq(index).show()

});
$('.store-link__item').on('click', function (){
    const index = $(this).index()
    $('.store-link__item').removeClass('active')
    $(this).addClass('active')
    $('.p').hide().eq(index).show()

});
$('#mobile-menu-button').on('click', function () {
    $('body').toggleClass('mobile-menu-open')

});
$('.mm__dropdown-title').on('click', function () {
    $(this).toggleClass('active')
    $(this).siblings('.mm__dropdown-items').slideToggle(300)

});
$('.catalog__filter-category-button').on('click', function () {
    const htmlText = $('.catalog__filter-category-button')
    $('.catalog__filter-category-items').slideToggle(300)
    htmlText.html(htmlText.html() === 'Свернуть' ? 'Показать все' : 'Свернуть');
});
$('.header-nav-search').on('click', function () {
    $('.header-nav-search-input').removeClass('hide')
});
$('.search').on('click', function () {
    $('body').addClass('search-open')
});
